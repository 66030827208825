import { signal } from '@angular/core'
import { Auth } from 'aws-amplify'
import { parseJwt } from 'jwt-helper/dist'
import { log, logDebug, logError, logWarning } from '../log'
import { doubleCheckNeedsActivation, redirect2RedwoodForActivation } from '../redwood'
import { generateUUID, isAndroid, isSafariOnIPadOrMac, storeToCookie } from '../utils'
import { EVENTS } from './EVENTS'
import { ISession } from './ISession'
import { getSession } from './amplify/amplify'
import { cognitoSession2User, signinByTokens } from './cognito'
import { AllowedParentOrigin } from './inIFrame'

export const TAB_ID = generateUUID()

const sendOutTokenChanged = (data: any) => {
    if (data?.access_token) {
       const jwt = parseJwt(data?.access_token)
       if (jwt) {
        const expirationTime = jwt.exp * 1000 // Convert seconds to milliseconds
        const currentTime = new Date().getTime()
        const isExpired = currentTime > expirationTime
        if (isExpired) {
            logDebug("Token is expired, so don't send it out", data?.access_token)
            return
        } else {
            const expirationDatetime = new Date(expirationTime)
            logDebug(`token that's get send out is not expired, it expires at ${expirationDatetime.toLocaleString()} token: ${data?.access_token}`)
        }
       }
    }
    const msg = { 
        expires_in: data?.expires_in,
        access_token: data?.access_token, 
        id_token: data?.id_token, 
        refresh_token: data?.refresh_token,
        event: EVENTS.tokenChange,
        user: data?.user 
    }
    if (isSafariOnIPadOrMac() || isAndroid()) { // safari receives an empty msg on postMessage, so we pass the msg by a cookie ...
        storeToCookie(msg)
    }
    CHANNEL.postMessage(msg, AllowedParentOrigin())
}

export const postMsg2ChannelOrWorker = async (msg: any, user?: any) => {
    msg["originTabId"] = TAB_ID
    if (!msg.user && msg.event !== EVENTS.logout) {
        if (user) {
            msg.user = user
        } else if (REDWOOD_USER()) {
            msg.user = REDWOOD_USER()
        } else if (msg.id_token && msg.access_token) {
            msg.user = await cognitoSession2User(msg)
            REDWOOD_USER.set(msg.user)
        }
    }

    if (SHARED_WORKER) {
        SHARED_WORKER.port.postMessage(msg)   
    }

    if (msg.event === EVENTS.logout) {
        msg = { 
            expires_in: 0,
            access_token: undefined, 
            id_token: undefined, 
            refresh_token: undefined,
            event: EVENTS.logout,
            user: undefined 
        }
    } 
    


    sendOutTokenChanged(msg)
} 

export let SHARED_WORKER: SharedWorker
try {
    SHARED_WORKER = new SharedWorker("/assets/shared-worker.js")
} catch(e) {
    logWarning("shared worker not supported in browser !")
}

export let CHANNEL : any = window.parent
/* future :
if ('BroadcastChannel' in window) { // BroadcastChannel is supported in this browser
  CHANNEL = new BroadcastChannel(`sso_channel_${location.hostname.split(".").slice(-2).join(".")}`)
  CHANNEL.addEventListener("messageerror", (event: any) => { 
    showToast(`BroadcastChannel error : ${JSON.stringify(event)}`, TOAST_LEVELS.ERROR, "Fout opgetreden")
  }) 
}*/ 

export let REDWOOD_USER = signal<any>(undefined)
let timer : NodeJS.Timeout
let refresh_interval = 60000 * 15
  
let moduleResolve: Function, moduleReject: Function
const onMessage = async (event: any) => {
    try {
        const data = event.data ? event.data : event
        if (data.originTabId === TAB_ID) {
            return // don't process our own messages
        }

        if (data.last_action === "logout") {
            await Auth.signOut()
            return 
        }
        /*if (REDWOOD_USER()) {
            if ((REDWOOD_USER() as any)?.uid !== data?.user?.uid) {
                REDWOOD_USER.set(data?.user)
                if (data?.user) {
                    handleUserAndSchoolRoles(data?.user)
                }
            }
        } else { */
            let session = await Auth.currentSession()
            if (!session) {
                session = await signinByTokens(data.id_token, data.access_token, data.refresh_token)
            } else {
                await cognitoSession2User(session)
            }
        //}

        if (CHANNEL) {
            if (!SHARED_WORKER && !data?.access_token) {
                moduleResolve()
                return
            }
            const msg: any = { 
                expires_in: data?.expires_in,
                access_token: data?.access_token, 
                id_token: data?.id_token, 
                refresh_token: data?.refresh_token,
                event: EVENTS.tokenChange,
                user: data?.user 
            }

            if (isSafariOnIPadOrMac()|| isAndroid()) { // safari receives an empty msg on postMessage, so we pass the msg by a cookie ...
                storeToCookie(msg)
            }

           // const copyData = { ... data }
           // copyData.originTabId = TAB_ID
           // sendOutTokenChanged(copyData)
        }

        const ru: any = REDWOOD_USER()
        if (ru?.needs_activation) {
            const still_needs_activation = await doubleCheckNeedsActivation(ru.username) // by-pass cache
            if (still_needs_activation) redirect2RedwoodForActivation()
        }
        moduleResolve()
    } catch(e) {
        logError(e)
        moduleReject(e)
        return
    }
}

export const initSharedWorker = async (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        moduleResolve = resolve
        moduleReject = reject
        try {
            if (SHARED_WORKER) {
                SHARED_WORKER.port.onmessage = onMessage
                SHARED_WORKER.port.onmessageerror = (error) => {
                    logError("onmessageerror", error)
                    reject(error)
                }
                SHARED_WORKER.port.start()
            } else {  // Safari + Chrome Android currently has no shared worker support
                try {
                    let session: ISession | undefined = await getSession()
                    let user: any = undefined
                    if (session) {
                        user = await cognitoSession2User(session)
                        REDWOOD_USER.set(user)
                    }
                    const arg = { user }
                    Object.assign(arg, session)
                    onMessage(arg)
                    if (!timer) {
                        timer = setInterval(async () => {
                            try {
                                session = await getSession()
                            } catch {
                                session = undefined
                            } 
                            if (!user && session) {
                                user = await cognitoSession2User(session)
                                REDWOOD_USER.set(user)
                            }
                            await onTimer(session)
                        }, refresh_interval)
                    }
                } catch(e) {
                    logError(e)
                } finally {
                    return
                }
            }
        } catch (e) {
            logError(e)
            reject(e)
        }
    })
}

export const tokenRefresh = async (clientId: string, refresh_token: string) => {
    try {
        const tokenEndpoint =  `https://cognito-idp.eu-central-1.amazonaws.com`
        let body = {
            ClientId: clientId,
            AuthFlow: "REFRESH_TOKEN_AUTH",
            AuthParameters: {
                "REFRESH_TOKEN": refresh_token,
                DEVICE_KEY: null
            }
        }
        const response = await fetch(tokenEndpoint, {
            method: 'POST',
            headers: {
                "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
                "X-Amz-User-Agent": "aws-amplify/5.0.4 js",
                'Content-Type': 'application/x-amz-json-1.1' //'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(body),
            mode: 'cors',
        })
        if (!response.ok) {
            throw new Error('Failed to refresh token')
        }
        const data = await response.json()
        return {
            access_token: data.AuthenticationResult.AccessToken,
            expires_in: data.AuthenticationResult.ExpiresIn,  
            id_token: data.AuthenticationResult.IdToken
        }
    } catch(e) {
      logError(e)
      return Promise.reject(e)
    }  
}

const onTimer = async (session: any) => {
    let result
    if (session) {
        result = await tokenRefresh(session.client_id, session.refresh_token)
    }
    log(`after token refresh, new token: ${JSON.stringify(result)}`)
    const msg = { 
        expires_in: result?.expires_in,
        access_token: result?.access_token, 
        id_token: result?.id_token, 
        refresh_token: session?.refresh_token,
        event: EVENTS.tokenChange,
        user: session?.user 
    }
    if (isSafariOnIPadOrMac() || isAndroid()) { // safari receives an empty msg on postMessage, so we pass the msg by a cookie ...
        storeToCookie(msg)
    }
    sendOutTokenChanged(msg)
}


