import '@angular/common/locales/global/nl-BE'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ReCaptchaV3Service } from 'ng-recaptcha'
import { BsModalService } from 'ngx-bootstrap/modal'
import { IUser } from "redwood-model/dist"
import { PACHIRA_USER_ROLES } from 'redwood-model/dist/ROLES'
import { Subscription } from 'rxjs'
import { logDebug, logError } from '../../log'
import { TOAST_LEVELS, showToast } from "../../toast"
import {PARAMS, isProd, POOL_NAMES, findPoolById} from '../PARAMS'
import { getCurrentAmplifyUser, getSession } from '../amplify/amplify'
import { TRANSLATION } from '../amplify/amplify_translations'
import { executeCaptchaAction, setCaptchaV3Service } from '../captcha'
import { EmailValidatorCodeComponent } from '../email-validator-code/email-validator-code.component'
import { REDWOOD_USER } from '../shared-worker-on-ui-thread'
import { clearDynamoTokenCache } from '../../utils'
import { fetchUserById, fetchUserPublications } from '../../redwood'

const serializeItem = (item: string, index: number) => {
  return `i:${index};s:${item.length}:"${item}"`
}

const phpSerialize = (strArray: string[]) =>  {
    if(!strArray?.length) return "a:0:{}"

    return `a:${strArray.length}:{${strArray.map((item, i) => serializeItem(item,i)).join(";") + ";"}}`
}

interface IPostalcode {
  zip: string,
  city: string,
  combined?: string
}
interface ICountry {
  name: string,
  code: string
}
const role_map: any =
{
  "ROLE_ADMIN": "Beheerder",
  "ROLE_USER": "Gebruiker",
  "ROLE_AUTHOR": "Auteur",
  "ROLE_DATA_PROTECTION_OFFICER" : "Data Protection Officer",
  "ROLE_DEVELOPER": "Developer",
  "ROLE_GARDIAN": "Voogd",
  "ROLE_PREMIUM": 'Premium',
  "ROLE_TEACHER": 'Leerkracht',
  "USER_THIRD_PARTY": "3de partij Gebruiker",
  "PUBLISHER_ADMIN": "Publisher Beheerder",
  "PUBLISHER_DESIGNER": "Publisher Designer",
  "PUBLISHER_DTP": "Publisher DTP",
  "PUBLISHER_AUTHOR": "Publisher Auteur"
}

const validateEmail = async(email: string) => {
  try {
    let url: string
    const captcha = await executeCaptchaAction('updateProfile')
    let body = {
      email,
      captcha
    }

    url = `${PARAMS.EMAIL_VALIDATOR_LAMBDA}/email_code`
    const response = await fetch(url,
    {
      headers: { "authorization" : `Bearer ${(await getSession()).access_token}`},
      method: "POST",
      body: JSON.stringify(body)
    })
    const data = await response.json()

    return data
  } catch(e) {
    logError(e)

    return Promise.reject(e)
  }
}

const GENDER = {
  "m": "Man",
  "f": "Vrouw"
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Input() user: IUser | undefined
  selectedCountry = "BE"
  profileForm: FormGroup | undefined
  genders: string[] = [ GENDER.m, GENDER.f ] // Options for the "Geslacht" select field
  userRoles: string[] = Object.values(role_map).sort() as string[] // Options for the "Gebruikersrollen" select field
  selectedRoles = [this.userRoles.find(ur => ur === 'Gebruiker')]
  selectedGender = ""
  selectedLocale: any
  countries: ICountry[] = []
  selectedPostalCode: IPostalcode | undefined
  isAdmin = false
  postalCodes: IPostalcode[] = []
  data: IPostalcode[] = []
  isEdit = false
  fromToggle = false
  locales = [
    { name: "Nederlands (België)", value: "nl-BE" },
    { name: "Nederlands (Nederland)", value: "nl-NL"},
    { name: "Frans (België)", value: "fr-BE"},
    { name: "Frans (Frankrijk)", value: "fr-FR"},
    { name: "Spaans (Spanje)", value: "es-ES"},
    { name: "English (UK)", value: "en-UK"},
    { name: "English (US)", value: "en-US"},
  ]
  initialValue: IPostalcode | undefined
  onCodeVerifiedSubscription: Subscription | undefined
  amplify_user: any

  getItemTempl(pc: IPostalcode) : string {
    return `${pc.zip} - ${pc.city}`
  }
  canChangePw(): boolean {
    const redwoodUser = REDWOOD_USER();

    if (!redwoodUser?.uid || !this.user?.uid) return false;

    const currentPool = findPoolById(this.amplify_user?.pool?.userPoolId)

    if (!currentPool) return false;

    if ( [POOL_NAMES.E_DUCATE_ME, POOL_NAMES.TEST_E_DUCATE_ME].indexOf(currentPool?.NAME) === -1) return false

    return redwoodUser.uid === this.user.uid
  }
  canEdit(): boolean {
    if (this.isYourOwnProfile) {
      return true
    }

    const validRoles: string[] = ['ROLE_ADMIN', 'ROLE_SCHOOL_ADMIN']

    const ru: any = REDWOOD_USER()
    if (validRoles.some(role => ru?.roles.includes(role))) {
      if (ru?.roles.includes('ROLE_SCHOOL_ADMIN')) {
        const adminSchoolIds: string[] = ru.school_role_id_map
          ?.filter((schoolMap: { school_role: string, school_id: string }) => schoolMap.school_role === 'ROLE_ADMIN')
          ?.map((schoolMap: { school_role: string, school_id: string }) => schoolMap.school_id) || []

        const userSchoolIds: string[] = this.user?.school_role_id_map
          ?.map((schoolMap: { school_role: string, school_id: string }) => schoolMap.school_id) || []

        if (adminSchoolIds.some(schoolId => userSchoolIds.includes(schoolId))) {
          return true
        }
      } else {
        return true
      }
    }

    return false
  }
  toggleEdit() {
    const profileReferrer = sessionStorage.getItem('profileReferrer')
    if (profileReferrer?.includes('redwood') || profileReferrer?.includes('localhost:8899')) {
      sessionStorage.setItem('preserveReferrer', 'true')
    }
    this.fromToggle = true
    this.isEdit = true
  }

  selectedValueRender(pc: any) : string {
    return `${pc.zip} - ${pc.city}`
  }
  searchPostalCode($event: string) {
    this.initialValue = undefined
    this.data = [...this.postalCodes.filter(pc => pc.zip.startsWith($event))]
  }

  get sizedAvatar(): string | undefined {

    return this.user?.avatar?.replace("s=32","s=128")
  }

  constructor(private formBuilder: FormBuilder,
              public router: Router,
              private route: ActivatedRoute,
              private bsModalService: BsModalService,
              private recaptchaV3Service: ReCaptchaV3Service
              ) {
                setCaptchaV3Service(recaptchaV3Service)
  }

  selectPostalcode($event: IPostalcode) {
    this.selectedPostalCode = $event
  }
  return() {
    const profileReferrer = sessionStorage.getItem('profileReferrer')
    this.clearSession()
    if (profileReferrer?.includes('redwood') || profileReferrer?.includes('localhost:8899')) {
      window.history.back()
      return
    }
    this.router.navigateByUrl('/login')
  }
  cancel() {
    const profileReferrer = sessionStorage.getItem('profileReferrer')

    if ((profileReferrer?.includes('redwood') || profileReferrer?.includes('localhost:8899'))) {
      if(!this.fromToggle){
        this.clearSession()
        window.history.back()
      } else {
        location.reload()
      }
      return
    }
    this.clearSession()
    location.reload()
  }
  addLicense() {
    if (!this.user?.id) {
      showToast('User-Id is niet gekend!', TOAST_LEVELS.ERROR)
      return
    }
    location.replace(`${PARAMS.REDWOOD_UI}/publication/licenses/create/${this.user.id}`)
  }
  schoolEdit() {
    if (!this.user?.id) {
      showToast('User-Id is niet gekend!', TOAST_LEVELS.ERROR)
      return
    }
    location.replace(`${PARAMS.REDWOOD_UI}/school/user/${this.user.id}`)
  }
  updateLicense(licenseId: number) {
    if(!licenseId) return
    location.replace(`${PARAMS.REDWOOD_UI}/publication/licenses/${licenseId}/update`)
  }
  removeLicense(licenseId: number) {
    if(!licenseId) return
    location.replace(`${PARAMS.REDWOOD_UI}/publication/licenses/${licenseId}/delete`)
  }
  clearSession() {
    sessionStorage.removeItem('preserveReferrer')
    sessionStorage.removeItem('profileReferrer')
  }

  isValid(sdate: string):boolean {
    if (!sdate) return true
    const exDate = new Date(sdate)
    const currDate = new Date()
    return exDate.getTime() > currDate.getTime()
  }

  get isYourOwnProfile() : boolean {
    const ru: any = REDWOOD_USER()
    return this.user?.id === ru?.id
  }

  async ngOnInit() {
    if (!JSON.parse(sessionStorage.getItem('preserveReferrer') || 'false')) {
      sessionStorage.setItem('profileReferrer', document.referrer)
    }
    const postalRequest = await fetch('/assets/postalcodes.json')
    const postalData = await postalRequest.json()
    this.postalCodes = postalData.map((pData: {zip: string, city: string, combined: string}) => {
      pData["combined"] = `${pData.zip} ${pData.city}`
      return pData
    })
    const countryRequest = await fetch('/assets/countries.json')
    this.countries = await countryRequest.json()

    try {
      this.amplify_user = await getCurrentAmplifyUser(true)
      if (!this.amplify_user) {
        throw new Error("Niet geauthenticeerd!")
      }
    } catch(e) {
      this.router.navigateByUrl("/")
      return
    }
    this.user = REDWOOD_USER() as IUser | undefined
    logDebug("user", this.user)
    if (this.user) {
      handleUserAndSchoolRoles(this.user)
    }

    this.isAdmin = !!(this.user?.roles.includes(PACHIRA_USER_ROLES.ROLE_ADMIN))
    const userIdstr = this.route.snapshot.paramMap.get('userId')

    if (userIdstr) {
      const validRoles: string[] = ['ROLE_ADMIN', 'ROLE_SCHOOL_ADMIN']
      let userId: number = 0
      try {
        userId = Number(userIdstr)
      } catch(e) {
        throw new Error("Ongeldig nummer in userId querystring")
      }
      if (this.user?.id !== userId && !validRoles.some(role => this.user?.roles.includes(role))) {
        showToast('Alleen administrators kunnen andere gebruikers beheren.', TOAST_LEVELS.ERROR, "Beveiligingsfout")
        throw new Error('Alleen administrators kunnen andere gebruikers beheren.')

      }

      this.user = await fetchUserById(userId)
      this.user["publications"] = await fetchUserPublications(userId)
      this.isEdit = this.route.snapshot.data['isEditMode'] && this.canEdit()
    } else if (this.user) {
      this.user["publications"] = await fetchUserPublications(this.user.id)
    }

    if (this.user?.country)
    this.selectedCountry = this.user.country
    this.selectedRoles = []

    if (this.user?.roles) {
      this.selectedRoles = this.user.roles.filter(r => r).map((ur: string) => role_map[ur])
    }

    switch (this.user?.sex?.toLocaleLowerCase()) {
      case "m":
        this.selectedGender = GENDER.m
        break
      case "f":
          this.selectedGender = GENDER.f
          break
    }

    if (this.user?.postal) {
      this.data.push({ zip: this.user.postal, city: this.user.city as string, combined: `${this.user.postal} ${this.user.city}` })
      this.initialValue = this.selectedPostalCode = this.data[0]
    }

    if (this.user?.lan_code) {
      const found = this.locales.find(l => l.value.toLowerCase() === this.user?.lan_code?.toLowerCase())
      this.selectedLocale = found?.value
    }

    this.profileForm = this.formBuilder.group({
      gebruikersnaam: [this.user?.username, Validators.required],
      emailadres: [this.user?.email, [Validators.required, Validators.email]],
      voornaam: [this.user?.first_name, Validators.required],
      familienaam: [this.user?.last_name, Validators.required],
      geslacht: [this.user?.sex],
      telefoon: [this.user?.phone],
      adres: [this.user?.address],
      postalCode: [this.user?.postal],
      gemeente: [this.user?.city],
      land: [this.user?.lan_code, Validators.required],
      gebruikersrollen: [this.user?.roles.filter(r => r)], //remove false (PHPUnserialize adds empty I suspect)
      alias: [this.user?.alias],
      taal: [this.user?.lan_code]
    })
  }

  ngOnDestroy(): void {
    this.onCodeVerifiedSubscription?.unsubscribe()
  }
  getRoles(roles?: string[]): string {
    if (!roles) return ''
    const newRoles =  roles.map(r => role_map[r]).filter(r => r)
    if (newRoles.length > 1) return newRoles.join(', ')

    return newRoles[0]
  }
  getCountry(code?: string): string {
    const country = this.countries.find(c => c.code === code)
    if (!code || !this.countries || !country) return ''

    return country.name
  }
  getMunicipal(pcode?: string): string {
    const postal = this.postalCodes.find(p => p.zip === pcode)
    if (!postal || !this.postalCodes || !postal) return ''

    return postal.combined || ''
  }
  getLanguage(code?: string): string {
    const locale =  this.locales.find(l => l.value.toLowerCase() === code?.toLowerCase())
    if (!code || !this.locales || !locale) return ''

    return locale.value
  }

  ngOnChanges(): void {
  }

  async onSubmit() {
    if (this.profileForm?.valid) {
      let updatedUser: any = { }
      const value = this.profileForm?.value
      const old_email = this.user?.email
      if (value.emailadres !== old_email) {
        await validateEmail(value.emailadres)
        const DIALOG_OPEN_OPTIONS = {
          ignoreBackdropClick: true,
          initialState: { email: value.emailadres }
        }
        const modalRef = this.bsModalService.show(EmailValidatorCodeComponent, DIALOG_OPEN_OPTIONS)
        this.onCodeVerifiedSubscription = modalRef.content?.OnCodeVerified$.subscribe(async (result: any) => {
          if (result.error) {
            showToast(result.error, TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
            return
          }
          await this.saveUser(updatedUser, value)
        })
      } else {
        await this.saveUser(updatedUser, value)
      }
    }
  }

  async saveUser(updatedUser: any, value: any) {
    updatedUser.id = this.user?.id
    updatedUser.address = value.adres
    updatedUser.lan_code = this.selectedLocale
    updatedUser.alias = value.alias
    updatedUser.country = value.land
    updatedUser.username = value.gebruikersnaam
    updatedUser.username_canonical = value.gebruikersnaam
    updatedUser.email = value.emailadres
    updatedUser.email_canonical = value.emailadres
    updatedUser.first_name = value.voornaam
    updatedUser.last_name = value.familienaam
    switch (this.selectedGender) {
      case GENDER.m:
        updatedUser.sex = "m"
        break
      case GENDER.f:
        updatedUser.sex = "f"
        break
    }
    updatedUser.phone = value.telefoon
    updatedUser.address = value.adres
    updatedUser.postal = this.selectedPostalCode?.zip
    updatedUser.city = this.selectedPostalCode?.city


    const userUpdates: any = updatedUser

    if (!this.selectedRoles.length) {
      this.selectedRoles = []
    }

    const roles_english = this.selectedRoles.filter(r => r).map(r => {
      const idx = Object.values(role_map).findIndex(value => (value as string).toLowerCase() === r?.toLowerCase())
      return Object.keys(role_map)[idx]
    })

    if (this.user) {
      handleUserAndSchoolRoles(this.user, roles_english)
    }

    userUpdates.roles = phpSerialize(roles_english.map(r => r))

    if (userUpdates.password) {
      // @ts-ignore: The operand of a 'delete' operator must be optional
      delete userUpdates.password
      delete userUpdates.school_roles
    }

    if (userUpdates.school_roles) {
      // @ts-ignore: The operand of a 'delete' operator must be optional
      delete userUpdates.school_roles
    }

    let url = PARAMS["REDWOOD-LAMBDA"]

    const body = { ...userUpdates, commandType: "UpdateUserCommand" }
    try {
      const token = this.amplify_user?.signInUserSession?.accessToken?.jwtToken || (await getSession()).access_token
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
      const data = await response.json()
      if (data?.error) {
        throw data.error
      }
      showToast("Wijzigingen succesvol opgeslagen.", TOAST_LEVELS.SUCCES)
      if (this.user) {
        Object.assign(this.user, userUpdates)
        this.user.roles = roles_english
      }

      await clearDynamoTokenCache(token as string)
      setTimeout(() => {
        this.return()
      }, 1500)
    } catch (e) {
      this.toggleEdit()
      showToast(`Wijzigingen werden niet doorgevoerd. fout : ${e}`, TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE )
      logError(e)
    }
  }

  changePSW() {
    this.router.navigateByUrl("/change-psw")
  }
  mfa() {
    this.router.navigateByUrl("/mfa")
  }
  async toggleStatus($event: Event) {
    if (!this.user) {
      showToast("Geen gebruiker geselecteerd, kan bijgevolg de status niet togglen.", TOAST_LEVELS.ERROR)
    
      return
    }  

    if (window.confirm(`Bent u zeker dat u de gebruiker wilt  ${!this.user.enabled ? "activeren" : "desactiveren"} ?`)) {
      const body = { 
        username: this.user.username,
        enable: !this.user.enabled,
        commandType: "ToggleUserStatusCommand" 
      }
      try {
        const token = this.amplify_user?.signInUserSession?.accessToken?.jwtToken || (await getSession()).access_token
        const response = await fetch(PARAMS["REDWOOD-LAMBDA"], {
          method: "POST",
          headers: {
            "authorization": `Bearer ${token}`,
            "id-token": this.amplify_user?.signInUserSession.idToken.jwtToken || (await getSession()).id_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        })
        const data = await response.json()
        if (data?.error) {
          throw data.error
        }
        this.user.enabled = !this.user?.enabled,
        showToast(`Gebruiker succesvol ${body.enable ? "geactiveerd" : "gedesactiveerd"}.`, TOAST_LEVELS.SUCCES)
      } catch(e: any) {
        logError(e)
        showToast(`Er is een fout opgetreden : ${e.messsage}`, TOAST_LEVELS.ERROR)
      }
    }
  }
}

export const handleUserAndSchoolRoles = (user: IUser, roles_english?: string[]): void => {
      const roles = roles_english || user.roles
      // get rid off the ridiculous role USER
      const idx = roles.indexOf("ROLE_USER")
      if (idx !== -1) {
        roles.splice(idx, 1)
      }
      if (user?.school_role_id_map && Object.keys(user.school_role_id_map).length) {
        const user_roles = roles.filter(r => Object.values(PACHIRA_USER_ROLES).includes(r))
        if (user_roles.length) {
          const mapRole2DutchWord = (role: string) => {
            switch(role) {
              case "ROLE_TEACHER":
                return "leerkracht"
              case "ROLE_STUDENT":
                return "student"
              case "ROLE_ADMIN":
                return "school beheerder"
              case "ROLE_OFFICE":
                return "secretariaat"
              default:
                return `Onbekende rol: ${role}`
            }

          }

          const msg = `Het toewijzen van een interne rol aan een gebruiker gekoppeld aan een school (${user.school_role_id_map.map(sr => mapRole2DutchWord(sr.school_role)).join(",")}) is niet toegestaan. <br /> Gelieve de rollen aan te passen hetzij in het  <a href='./profile'>gebruikersprofiel</a>, hetzij in redwood school beheer.`

          showToast(msg, TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)

          return
        }
      }
}

