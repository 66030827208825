<div class="container">
  <form class="centered-content amplifyBorders" data-amplify-form *ngIf="profileForm && isEdit" [formGroup]="profileForm" (ngSubmit)="onSubmit()" >
   <h3 class="form-group">Profiel</h3>
    <div class="imageContainer form-group">
      <img [src]="sizedAvatar" />
    </div>
    <div class="amplify-flex amplify-authenticator__column" data-orientation="horizontal">

      <div class="form-group">
        <label class="amplify-label" for="emailadres">E-mail</label>
        <input type="email" class="amplify-input" id="emailadres" formControlName="emailadres">
        <div *ngIf="profileForm.get('emailadres')?.errors && profileForm.get('emailadres')?.dirty" class="error">
          E-mailadres is verplicht en moet geldig zijn.
        </div>
      </div>

      <div class="form-group">
        <label class="amplify-label" for="voornaam">Voornaam</label>
        <input type="text" class="amplify-input" id="voornaam" formControlName="voornaam">
        <div *ngIf="profileForm.get('voornaam')?.errors && profileForm.get('voornaam')?.dirty" class="error">
          Voornaam is verplicht.
        </div>
      </div>

      <div class="form-group">
        <label class="amplify-label" for="familienaam">Familienaam</label>
        <input type="text" class="amplify-input" id="familienaam" formControlName="familienaam">
        <div *ngIf="profileForm.get('familienaam')?.errors && profileForm.get('familienaam')?.dirty" class="error">
          Familienaam is verplicht.
        </div>
      </div>

      <div class="form-group">
        <label class="amplify-label" for="alias">Alias</label>
        <input type="text" class="amplify-input" id="alias" formControlName="alias">
      </div>

      <div class="form-group">
        <label class="amplify-label" for="geslacht">Geslacht</label>
        <select class="amplify-select amplify-field-group__control" [(ngModel)]="selectedGender" id="geslacht" name="geslacht" formControlName="geslacht">
          <option *ngFor="let gender of genders" [value]="gender">{{ gender }}</option>
        </select>
        <div *ngIf="profileForm.get('geslacht')?.errors && profileForm.get('geslacht')?.dirty" class="error">
          Geslacht is verplicht.
        </div>
      </div>

      <div class="form-group">
        <label class="amplify-label" for="telefoon">Telefoon</label>
        <input type="text" class="amplify-input" id="telefoon" name="telefoon" formControlName="telefoon">
      </div>

      <div class="form-group">
        <label for="adres">Adres</label>
        <input type="text" class="amplify-input" id="adres" name="adres" formControlName="adres">
      </div>

      <div class="form-group">
        <label for="postalCode">Postcode/Gemeente</label>
        <div class="ng-autocomplete postalCode">
          <ng-autocomplete
            class=""
            [data]="data"
            searchKeyword="combined"
            (selected)='selectPostalcode($event)'
            (inputChanged)="searchPostalCode($event)"
            [itemTemplate]="itemTemplate"
            [initialValue]="initialValue"
            notFoundText="Niet gevonden"
            [selectedValueRender]="selectedValueRender"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
          <a [innerHTML]=item.combined></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>

      <div class="form-group">
        <label class="amplify-label" for="land">Land</label>
        <select class="amplify-select amplify-field-group__control" id="land" formControlName="land" [(ngModel)]="selectedCountry">
          <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
        </select>
        <div *ngIf="profileForm.get('land')?.errors && profileForm.get('land')?.dirty" class="error">
          Land is verplicht.
        </div>
      </div>

      <div class="form-group">
        <label class="amplify-label" for="taal">Taal</label>
        <select class="amplify-select amplify-field-group__control" id="taal" formControlName="taal" [(ngModel)]="selectedLocale">
          <option *ngFor="let locale of locales" [value]="locale.value">{{ locale.name }}</option>
        </select>

      </div>

      <div class="form-group userRoles" *ngIf="isAdmin">
        <label class="amplify-label" for="gebruikersrollen">Gebruikersrollen</label>
        <select class="amplify-select amplify-field-group__control"
          [(ngModel)]="selectedRoles"
          id="gebruikersrollen" formControlName="gebruikersrollen" multiple>
          <option *ngFor="let role of userRoles" [value]="role">{{ role }}</option>
        </select>
      </div>

      <div class="form-group" *ngIf="isAdmin">
        <label class="amplify-label" for="isActief">Actief</label>
        <button type="button" class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--destructive" (click)="toggleStatus($event)">{{this.user?.enabled ? "Deactiveer" : "Activeer" }}</button>
      </div>

      <div class="form-group buttons">
        <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" type="submit" [disabled]="!profileForm.valid">Wijzigingen opslaan</button>
        <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--destructive" (click)="cancel()">Annuleer</button>
      </div>

    </div>

  </form>
  <div *ngIf="!isEdit" class="informative centered-content amplifyBorders">
    <h3 class="form-group">Profiel</h3>
    <div class="imageContainer form-group">
      <img [src]="sizedAvatar" />
    </div>
    <p *ngIf="user?.username" ><label class="amplify-label">Gebruikersnaam: </label><span>{{user?.username}}</span></p>
    <p *ngIf="user?.email" ><label class="amplify-label">E-mail: </label><span>{{user?.email}}</span></p>
    <p *ngIf="user?.first_name" ><label class="amplify-label">Voornaam: </label><span>{{user?.first_name}}</span></p>
    <p *ngIf="user?.last_name" ><label class="amplify-label">Familienaam: </label><span>{{user?.last_name}}</span></p>
    <p *ngIf="user?.alias" ><label class="amplify-label">Alias: </label><span>{{user?.alias}}</span></p>
    <p *ngIf="user?.sex" ><label class="amplify-label">Geslacht: </label><span>{{user?.sex}}</span></p>
    <p *ngIf="user?.address" ><label class="amplify-label">Adres: </label><span>{{user?.address}}</span></p>
    <p *ngIf="user?.postal" ><label class="amplify-label">Gemeente: </label><span>{{getMunicipal(user?.postal)}}</span></p>
    <p *ngIf="user?.country" ><label class="amplify-label">Land: </label><span>{{getCountry(user?.country)}}</span></p>
    <p *ngIf="user?.lan_code" ><label class="amplify-label">Taal: </label><span>{{getLanguage(user?.lan_code)}}</span></p>
    <p *ngIf="user?.roles" ><label class="amplify-label">Gebruikersrollen: </label><span>{{getRoles(user?.roles)}}</span></p>
    <div *ngIf="user?.publications?.length" class="licenses">
      <label class="amplify-label">Licenties:</label>
      <ul>
        <li *ngFor="let pub of user?.publications" [ngClass]="{'license': true, 'expired': !isValid(pub?.publication_licenses_expired_at)}">
          <span class="name">{{pub.slug}}</span>
          <span class="right">
            <span *ngIf="pub?.publication_licenses_expired_at" class="expiration">
              <span *ngIf="isValid(pub?.publication_licenses_expired_at)">Tot: {{pub.publication_licenses_expired_at | date:'dd/MM/yyyy':'nl-BE' }}</span>
              <span *ngIf="!isValid(pub?.publication_licenses_expired_at)">Verlopen</span>
            </span>
          <span *ngIf="pub.publication_licenses_id && isAdmin" class="actions">
            <span (click)="updateLicense(pub.publication_licenses_id)"><i class="icon-pencil-2"></i></span>
            <span (click)="removeLicense(pub.publication_licenses_id)"><i class="icon-bin-2"></i></span>
          </span>
          </span>

        </li>
      </ul>
    </div>
    <div class="form-group buttons">
      <ng-container *ngIf="isAdmin && user">
        <button  class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" (click)="addLicense()">Licentie toevoegen</button>
        <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" (click)="schoolEdit()">School beheren</button>
      </ng-container>
      <ng-container *ngIf="canChangePw()">
        <button  class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;"(click)="changePSW()">Wijzig Wachtwoord</button>
        <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" style="font-weight: normal;" (click)="mfa()">Beheer MFA</button>
      </ng-container>
      <button *ngIf="canEdit()" class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--primary" (click)="toggleEdit()">Bewerken</button>
      <button class="amplify-button amplify-button--fullwidth amplify-button--medium amplify-button--destructive" (click)="return()">Terug</button>
    </div>
  </div>
</div>

