import { Auth } from 'aws-amplify'
import { IPool, PARAMS } from "../app/PARAMS"
import { initAmplify } from "../app/amplify/amplify"
import { saveAmplifyStateOnServer } from "../app/amplify/amplify_state"
import { TRANSLATION } from "../app/amplify/amplify_translations"
import { STORAGE } from "../app/server-storage"
import { log, logDebug, logError, logWarning } from "../log"
import { QS_CODE, USER_MAPPING_INFO_KEY, qs } from "../main"
import { TOAST_LEVELS, showToast } from "../toast"
import { IOnTokenResult } from './common'

export const onSmartSchoolToken = async (session: any, pool: IPool): Promise<IOnTokenResult> => {
    const username = session?.user?.username
    if (!(session?.user)) {
        STORAGE.setItem(USER_MAPPING_INFO_KEY, JSON.stringify({
            "destination_id": session.smartschool_user?.userID,
            "pool": pool.NAME,
            "token": session?.access_token,
            "userInfo": session.smartschool_user,
        }))
        location.href = `${location.origin}/map`

        return {
            exit: true,
            session,
            needsActivation: false
        }
    }

    const { signInUserSession } = await customSignInWithSmartSchool(username, session?.access_token, pool)
    if (!signInUserSession) {
        const msg = "Smartschool authenticatie is gefaald"
        showToast(msg, TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)

        return Promise.reject(msg)
    }
    const access_token = signInUserSession.accessToken?.jwtToken || signInUserSession.access_token
    const smartschoolUserAttributes = Object.values(session.cognito_user_attributes)

    // store cognito smartschool token in dynamo token_info for authentication
    const url = `${PARAMS.SMARTSCHOOL_LAMBDA}?${qs}`
    const smartResponse = await fetch(url, {
        method: "PUT", body: JSON.stringify({
            "token": access_token,
            "user_id": session?.user.id
        })
    })
    const data = await smartResponse.json()
    const fedInfo = Object.assign({}, { user: await Auth.currentAuthenticatedUser() }, { token: access_token })
    if (fedInfo) {
        STORAGE.setItem('aws-amplify-federatedInfo', JSON.stringify(fedInfo))
    }
    // HACK: push received token + user into amplify, by storing it in is state en restarting it
    await saveAmplifyStateOnServer(
        pool,
        smartschoolUserAttributes,
        signInUserSession,
        username
    )

    return {
        exit: false,
        session: signInUserSession,
        mappedUser: session.user,
        needsActivation: session.user?.needs_activation
    }
}

const sanitizeUsername = (username: string) => {
    return username.toLocaleLowerCase().replace(/\s/g, "")
}

export const customSignInWithSmartSchool = async (username: string, token: string, pool: IPool): Promise<any> => {
    try {
        await initAmplify(pool.USER_POOL_ID)
        const challenge = await Auth.signIn(sanitizeUsername(username))
        if (challenge.challengeName === 'CUSTOM_CHALLENGE') {
            try {
                const challenge_answer = await Auth.sendCustomChallengeAnswer(challenge, token)
                log("challenge_answer", challenge_answer)
            } catch (e) {
                logError(e)
            }
        } else {
            logWarning(`challenge : ${challenge.challengeName}`, challenge)
        }
        logDebug("user customSignInWithSmartSchool", challenge)

        return challenge
    } catch (e) {
        logError(e)
        throw new Error(`Custom authentication failed. error : ${e}, token provided : ${token}`)
    }
}

export const exchangeCodeForSmartschoolToken = async (code: string) => {
    const qs = new URLSearchParams()
    let url: string
    qs.append('code', code);
    url = `${PARAMS.SMARTSCHOOL_LAMBDA}?${qs}`
    const smartResponse = await fetch(url)
    const data = await smartResponse.json()
    if (qs.has(QS_CODE)) {  // remove code qs from querystring otherwise amplify will start code flow with the code from smartschool ...
        qs.delete(QS_CODE)
        const newUrl = `${window.location.pathname}?${qs.toString()}`
        history.replaceState({}, document.title, newUrl)
    }

    return data
} 