import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { logError } from '../../log';
import { TOAST_LEVELS, showToast } from "../../toast";
import { IPool, PARAMS } from '../PARAMS';
import { getCurrentAmplifyUser, getSession } from '../amplify/amplify';
import { getPool, saveAmplifyStateOnServer } from '../amplify/amplify_state';
import { TRANSLATION } from '../amplify/amplify_translations';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss']
})
export class MfaComponent implements OnInit{
  qrCodeImageUrl = ""
  verification_code = ""
  isDisabled = false
  currentStep = 0
  user: any
  isCodeSixDigits = false

  constructor(public router: Router,){}

  async ngOnInit() {
    this.user = await getCurrentAmplifyUser()
    if (!this.user) {
      this.router.navigateByUrl("/")
    }
  }
  cancel() {
    this.router.navigateByUrl('/profile');
  }

  get MFAEnabled(): boolean {
    return this.user?.preferredMFA === 'SOFTWARE_TOKEN_MFA'
  }

  async disable_MFA() {
    try {
      await Auth.setPreferredMFA(this.user, 'NOMFA')
      showToast("MFA inactief vanaf heden.", TOAST_LEVELS.INFO);
      await Auth.signOut();
      this.router.navigateByUrl("/")
    } catch(e) {
      logError(e)
    }
  }

  async get_qr() {
    try {
      // Change cursor to hourglass
      document.body.style.cursor = 'wait'
      this.isDisabled = true
      let urlSearchParams = new URLSearchParams()
      urlSearchParams.append("access_token", (await getSession()).access_token as string)
      urlSearchParams.append("action", "associate")
      const response = await fetch(`${PARAMS.SETUP_MFA_LAMBDA}?${urlSearchParams}`)
      const blobResponse = await response.blob();
      this.qrCodeImageUrl = URL.createObjectURL(blobResponse)
      this.currentStep++
    } catch (error) {
      this.isDisabled = false
      showToast("Kan QR-code niet laden.", TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
      logError('Error associate_token_mfa:' + error)
    } finally {
      // Change cursor to hourglass
      document.body.style.cursor = 'auto'
    }

  }

  async finalize_mfa() {
      try {
        let urlSearchParams = new URLSearchParams()
        const session = await getSession()
        const { access_token } =  session
        urlSearchParams.append("access_token", access_token as string)
        urlSearchParams.append("action", "setup_mfa")
        const response = await fetch(`${PARAMS.SETUP_MFA_LAMBDA}?${urlSearchParams}`)
        const resp = await response.text()
        showToast("MFA setup klaar.", 'success')
        this.currentStep++
        await saveAmplifyStateOnServer(getPool() as IPool, this.user.attributes, session, this.user.username)
        await Auth.signOut()
        this.router.navigateByUrl("/")

      } catch (error) {
        showToast("Kan MFA niet finaliseren.", TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
        logError('Error setup_mfa:' + error);
      }
  }

  async verify_mfa() {
      try {
        let urlSearchParams = new URLSearchParams()
        urlSearchParams.append("access_token", (await getSession()).access_token as string)
        urlSearchParams.append("verification_code", this.verification_code)
        urlSearchParams.append("action", "verify")
        const response = await fetch(`${PARAMS.SETUP_MFA_LAMBDA}?${urlSearchParams}`)
        const text = await response.text()
        if (text === "Verify succeeded") {
          showToast("Code verificatie succesvol.", TOAST_LEVELS.SUCCES)
          this.currentStep++
        } else {
          showToast("Code verificatie is gefaald, probeer het eens opnieuw. Een code is slechts 30 seconden geldig.", TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
        }
     } catch (error) {
        showToast("Kan MFA niet verifiëren", TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
        logError('Error verificatie code :' + error)
     }
  }

  input($event: Event) {
    if ($event.currentTarget) {
      const value = ($event.currentTarget as any).value
      if (value && Number.parseInt(value)) {
        this.isCodeSixDigits = value.length === 6
      }
    }
  }
}
