import { IJWT } from "../IJWT";


export const extractPOOL_IDFromJWT = (jwt: IJWT) : string => {
    const parts = jwt.iss.split('/')
    const extractedString = parts[parts.length - 1]

    return extractedString
}


const parseUserPoolFromJWT = (JWT: any): string => {
    const parts = JWT?.iss?.split("/")
  
    return parts[parts.length - 1]
}

