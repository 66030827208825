export const AMPLIFY_TRANSLATIONS = {
    nl: {
      "Sign In": "Inloggen",
      "Create Account": "Profiel Aanmaken",
      "Family Name": "Familienaam",
      "Enter your Family Name": "Vul je familienaam in.",
      "Given Name": "Voornaam",
      "Enter your Given Name": "Vul je voornaam in.",
      "Middle Name": "Middennaam",
      "Enter your Middle Name": "Vul je middennaam in.",
      "Enter your Nickname": "Vul je alias in.",
      "Nickname": "Alias",
      "Enter your username": "Vul je email of gebruikersnaam in",
      "Enter your Phone Number": "Vul je telefoon in.",
      "Incorrect username or password.": "Ongeldige gebruikersnaam wachtwoord combinatie.",
      "Username cannot be of email format, since user pool is configured for email alias.": "Gebruikersnaam mag niet in email adres formaat zijn.",
      "PreSignUp failed with error {error}": "Aanmaken account is gefaald met volgende fout :",
      "Password did not conform with policy: Password must have symbol characters": "Wachtwoord moet tenminste 1 speciaal teken bevatten.",
      "Invalid verification code provided, please try again.": "Ongeldige verificatie code, probeert u het a.u.b. opnieuw.",
      "Forgot your password?": "Wachtwoord vergeten?",
      "Password did not conform with policy: Password must have uppercase characters": "Wachtwoord moet hoofdletter bevatten.",
      "Invalid code provided, please request a code again.": "Ongeldige code, gelieve een nieuwe code aan te vragen.",
      "User already exists": "Deze gebruiker bestaat reeds. Indien u reeds een onafgewerkte registratie hebt, gelieve dan minimum 5 min te wachten alvorens opnieuw te proberen.",
      "Password did not conform with policy: Password not long enough": "Paswoord niet lang genoeg.",
      "Password did not conform with policy: Password must have numeric characters": "Passwoord met ten minste 1 cijfer bevatten.",
      "Password attempts exceeded":  "Teveel mislukte login pogingen.",
      "User does not exist": "Gebruiker onbekend.",
      "Access Token has been revoked.": "Uw huige sessie wordt als ongeldig gemarkeerd.",
      "Your software token has already been used once.": "Uw code is vervallen, probeer het opnieuw met de meest recente code.",
      "Invalid code received for user": "Ongeldige code ontvangen voor gebruiker",
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256": "Wachtwoord is gelimiteerd op 256 characters.",
      "Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256": "Wachtwoord is gelimiteerd op 256 characters.",
      "UserNotFoundException": "Onbekende gebruikersnaam/paswoord combinatie.",
      "Password does not conform to policy: Password must have symbol characters": "Paswoord voldoet niet aan de regeltjes : Het dient een speciaal character (!1@?) te bevatten.",
      "Password does not conform to policy: Password must have numeric characters": "Paswoord voldoet niet aan de regeltjes : Het dient een cijfer te bevatten.",
      "Password does not conform to policy: Password not long enough": "Paswoord voldoet niet aan de regeltjes : Het is niet lang genoeg.",
      "Password does not conform to policy: Password must have lowercase characters": "Paswoord voldoet niet aan de regeltjes : Het moet kleine letters bevatten.",
      "Password does not conform to policy: Password must have uppercase characters": "Paswoord voldoet niet aan de regeltjes : Het moet hoofdletters bevatten.",
      "Attempt limit exceeded, please try after some time.": "Maximum aantal mislukte pogingen bereikt, probeer het later eens opnieuw.",
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+": "Uw gebruikersnaam bevat spaties. Ons nieuwe systeem laat dit helaas niet meer toe. Gelieve in te loggen door uw email in het gebruikersnaam veld in te vullen.",
      "User is disabled.": "Gebruiker is gedeactiveerd."
    }
}

export const TRANSLATION = {
  "ERROR_TITLE" : "Er is een fout opgetreden !",
  "WARN_TITLE" : "Waarschuwing"
}
