import { AfterViewChecked, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, effect } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticatorService } from '@aws-amplify/ui-angular'
import { Auth } from 'aws-amplify'
import { ReCaptchaV3Service } from 'ng-recaptcha'
import { Subscription } from 'rxjs'
import { reInitAmplify } from 'src/utils'
import { log, logDebug, logError, logInfo, logWarning } from '../../log'
import { QS_RE_INIT, USER_MAPPING_INFO_KEY, isMAPPING_USER } from '../../main'
import { TOAST_LEVELS, showToast } from '../../toast'
import { PARAMS, POOL_NAMES, findPoolByClientId, getDefaultPool } from '../PARAMS'
import { AUTH$, AUTH_EVENTS, getCurrentAmplifyUser, getSession, initAmplify } from '../amplify/amplify'
import { TRANSLATION } from '../amplify/amplify_translations'
import { executeCaptchaAction, setCaptchaV3Service } from '../captcha'
import { fetchPswPolicy, isUserKnownToCognito, policyCache } from '../cognito'
import { MappingNullObjectStorage, STORAGE, setStorage } from '../server-storage'
import { REDWOOD_USER, SHARED_WORKER, postMsg2ChannelOrWorker } from '../shared-worker-on-ui-thread'

// 15 (in regex below) is currently the longest top level domain in IANA db
const validMailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/
let authenticatorService: AuthenticatorService | undefined
let pwButton: any
let usernameInputField: any
let passwordInputField: any
let submitButton: any
let component: AmplifyLoginComponent, username_value: string
let oldSubmitButton: any, oldPwButton : any, oldUsernameInputField: any, oldPasswordInputField: any

const sanitizeInput = (value: string): string => {
  value = value.toLowerCase()
  value = value.replaceAll(" ", "")

  return value
}

async function onSubmitClick(e: Event) {
  if (usernameInputField) {
    e.preventDefault()
    const [username, password] = await (new Promise<[string, string]>(async (resolve) => {
      usernameInputField["value"] = sanitizeInput(usernameInputField["value"])
      const password: string = passwordInputField["value"] as string
      if (usernameInputField["value"]?.includes("@")) {
        const isKnown =  await isUserKnownToCognito(usernameInputField["value"])
        if (!isKnown) {
          usernameInputField["value"] = usernameInputField["value"].split("@")[0]
        }
        resolve([usernameInputField['value'], password]);
      }
      resolve([usernameInputField['value'], password]);
    }))

    if (username && password) {
      submitButton?.removeEventListener('click', onSubmitClick);
      (submitButton as HTMLButtonElement).click()
    }
  }
}

async function onForgotPswButtonClick(e: Event){
  e.preventDefault()

  if (component.currentUserCannotResetPw) {
    component.currentUserCannotResetPw = false;
    window.open('https://diligentia.zendesk.com/hc/nl/requests/new', '_blank')
  }

  const isKnown =  await isUserKnownToCognito(username_value)
  if (!isKnown) {
    console.log('find log', {username_value})
    const encodedUri = encodeURI(`/reset-pw?username=${username_value}`);

    window.location.replace(encodedUri);
  } else {
    component.authenticator.toResetPassword()
  }

}

function onInput(event: any)  {
  username_value = event.target.value
  if (username_value !== component.lastKnownUsername) {
    component.currentUserCannotResetPw = false;
  }
}

function onSubmitByEnter(event: any): any {
  if (event.keyCode === 13 && submitButton) { // Check if Enter key is pressed
    if (event.currentTarget !== document.body) {
      event.preventDefault()
    }
    (submitButton as HTMLButtonElement).click()
  }
}

const isSignup = (): boolean => {
  return location.pathname.toLowerCase() === "/signup"
}

@Component({
  selector: 'app-amplify-login',
  templateUrl: './amplify-login.component.html',
  styleUrls: ['./amplify-login.component.scss']
})
export class AmplifyLoginComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Output() userIsAuthenticated = new EventEmitter<boolean>()
  destination_pool = ""
  TEST_E_DUCATE_ME = POOL_NAMES.TEST_E_DUCATE_ME
  E_DUCATE_ME = POOL_NAMES.E_DUCATE_ME
  captchaSubscription: Subscription | undefined
  authSubscription: Subscription | undefined
  userSubscription: Subscription | undefined //TODO: check if used
  currentUserCannotResetPw = false
  lastKnownUsername = ''
  userDisplayName = ''
  showAmplifyAuthenticator = true
  @ViewChild('amplifyContainer', { read: ElementRef }) amplifyContainer!: ElementRef

  services = {
    async handleSignUp(formData: Record<string, any>) {
      const captcha_token = await executeCaptchaAction('registerSSO')
      let { username, password, attributes } = formData

      const capthaHidden = document.querySelector("#captchaHidden") as HTMLInputElement
      const captchaRestHidden = document.querySelector("#captchaRest") as HTMLInputElement

      const maxLength = 2047
      if (captcha_token.length > maxLength) {
        logWarning("splitting up captcha")
        // Split the string into two parts
        const part1 = captcha_token.slice(0, maxLength);  // First 2047 characters
        const part2 = captcha_token.slice(maxLength);
        attributes["custom:captchaHidden"] = capthaHidden.value = part1
        attributes["custom:captchaRest"] = captchaRestHidden.value = part2
      } else {
        attributes["custom:captchaHidden"] = capthaHidden.value = captcha_token
      }

      username = username.toLowerCase()
      attributes.email = attributes.email.toLowerCase()
      const validEmail = validMailRegEx.test(attributes.email)
      if (!validEmail) {
        const msg = "Ongeldig e-mail formaat."
        showToast(msg, TOAST_LEVELS.ERROR, TRANSLATION.ERROR_TITLE)
        return Promise.reject(msg)
      }

      const honeyPot = document.querySelector('[name="custom:honeyPot"]') as HTMLInputElement
      attributes["custom:honeyPot"] = honeyPot["value"]
      return Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      })
    },
    async validateCustomSignUp(formData: Record<string, string>): Promise<{ [key: string]: string[] } | null> {
      const { password: passwordInput, email: emailInput, nickname: nicknameInput, username: usernameInput } = formData
      const {
        MinimumLength,
        RequireUppercase,
        RequireLowercase,
        RequireNumbers,
        RequireSymbols
      } = await fetchPswPolicy();
      const errors: {type: string, message:string}[] = [];

      if (passwordInput) {
        if (MinimumLength && passwordInput.length < MinimumLength) {
          errors.push({type: 'password', message: `Wachtwoord moet minimaal ${MinimumLength} tekens lang zijn` })
        }

        if (RequireNumbers && !/\d/.test(passwordInput)) {
          errors.push({type: 'password', message: 'Wachtwoord moet minimaal 1 cijfer bevatten' })
        }

        if (RequireSymbols && !/[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(passwordInput)) {
          errors.push({type: 'password', message: 'Wachtwoord moet minimaal 1 speciaal teken bevatten' })
        }

        if (RequireUppercase && !/[A-Z]/.test(passwordInput)) {
          errors.push({type: 'password', message: 'Wachtwoord moet minimaal 1 hoofdletter bevatten' })
        }

        if (RequireLowercase && !/[a-z]/.test(passwordInput)) {
          errors.push({type: 'password', message: 'Wachtwoord moet minimaal 1 kleine letter bevatten' })
        }
      }
      if (emailInput) {
        if (!validMailRegEx.test(emailInput)) {
          errors.push({type: 'email', message: 'Geen geldig e-mailadres' })
        }
      }
      if (usernameInput) {
        if (!/^[a-zA-Z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/u.test(usernameInput)) {
          errors.push({type: 'username', message: 'Gebruik alleen letters, cijfers, symbolen en vermijd spaties' })
        }
      }

      if (nicknameInput) {
        if (!/^[a-zA-Z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/u.test(nicknameInput)) {
          errors.push({type: 'nickname', message: 'Gebruik alleen letters, cijfers, symbolen en vermijd spaties' })
        }
      }


      if (errors.length) {
        const email = errors.filter(err => err.type === 'email').map(err => err.message);
        const password = errors.filter(err => err.type === 'password').map(err => err.message);
        const nickname = errors.filter(err => err.type === 'nickname').map(err => err.message);
        const username = errors.filter(err => err.type === 'username').map(err => err.message);

        return { email, password, nickname, username }
      }

      return null; //validation is done
    }
  }

  constructor(
    public authenticator: AuthenticatorService,
    public router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    setCaptchaV3Service(recaptchaV3Service)
    authenticatorService = authenticator
    effect(async () => {
        const redwood_user: any = REDWOOD_USER()
         const outOfSync = (this.authenticator.authStatus === "unauthenticated" && redwood_user) ||
        (this.authenticator.authStatus === "authenticated" && !redwood_user)
        if (outOfSync) {
          reInitAmplify()
        } else {
          const params = new URLSearchParams(window.location.search)
          if (params.has(QS_RE_INIT)) {
            params.delete(QS_RE_INIT)
            window.history.replaceState(null, '',  `${window.location.pathname}?${params.toString()}`)
          }
        }

        if (!redwood_user) {
          this.userDisplayName = ''
          return
        }
        const {first_name, last_name} = redwood_user

        if (first_name) {
          this.userDisplayName = `${first_name} ${last_name}`
          return
        }
        this.userDisplayName = redwood_user.username
    })
  }

  get isMapUser() : boolean {
    return isMAPPING_USER()
  }

  async ngOnInit() {
    try {
      component = this
      const qs = new URLSearchParams(location.search)
      // we always login/register on educate.me, this pool might differ from the current logged in
      let pool = getDefaultPool()

      if (isMAPPING_USER()) {
        let body: any, strBody: string, response: any, result: any
        const error_msg =  "Er lijkt iets misgegaan te zijn. Ga terug in je browser en probeer het opnieuw."
        const error_title = TRANSLATION.ERROR_TITLE
        const stateStr = STORAGE.getItem(USER_MAPPING_INFO_KEY)
        const mappingStorage = new MappingNullObjectStorage()
        Object.assign(mappingStorage, STORAGE)
        setStorage(mappingStorage)
        if (!stateStr) {
          showToast(error_msg, TOAST_LEVELS.ERROR, error_title)
          return
        }

        let state: any
        try {
          state = JSON.parse(stateStr)
        } catch (error) {
          showToast(error_msg, TOAST_LEVELS.ERROR, error_title)
          return
        }

        this.destination_pool = state.pool
      } else {
        setStorage(localStorage)
      }

      if (! await getCurrentAmplifyUser()) {
        await initAmplify(pool?.USER_POOL_ID)
      }

      // HACK: select +32 country code
      const parentElement = document.getElementsByTagName('app-login')[0]
      parentElement?.addEventListener('click', (event) => {
        const t = event?.target as any
        if (t?.className === "amplify-tabs-item") {
          const countrySelect: HTMLSelectElement = document.querySelector('select[name="country_code"]') as HTMLSelectElement
          for (let i = 0; i < countrySelect?.options.length; i++) {
            let option = countrySelect.options[i]

            // Check if the value of the option is equal to '+32'
            if (option.value === '+32') {
              // Set the 'selected' property of the option to true
              option.selected = true
              break // Exit the loop once the desired option is found
            }
          }
        }
      }
      )

      document.addEventListener("visibilitychange", async () => {
        if (document.visibilityState === "visible" && SHARED_WORKER) {
          postMsg2ChannelOrWorker({
            event: "status"
          })
        }
      }, false)

      this.authSubscription = AUTH$.subscribe(async (data: { payload: any }) => {
        const { payload } = data
        logDebug("hub received", data)

        try {
          switch(payload.event) {
            case AUTH_EVENTS.signIn:
              await this.getCurrentAmplifyUserAndPool()
              const pool = findPoolByClientId(payload.data.pool.clientId)
              if (pool) {
                this.destination_pool = pool.NAME
              }
              break
            }
        } catch(e) {

        }
      })
      try {
        // remove existing cache and make first request to populate cache
        await caches.delete(policyCache);
        await fetchPswPolicy()
      } catch {

      }
      if (!this.destination_pool) {
        try {
          await this.getCurrentAmplifyUserAndPool()
        } catch(ignore) {
        }
      }

      document.body.addEventListener("keypress", onSubmitByEnter)
    } catch(e) {
      logError("Exception onInit amplify component", e)
    } finally {
      if (isSignup()) {
        try {
          if (await getSession()) {
            showToast("U moet eerst uitloggen, alvorens u een nieuwe user kan registreren.", TOAST_LEVELS.WARN)
            return
          }
        } catch {}
        this.authenticator.toSignUp()
      }
    }
  }

  prefillRegistrationFormFromClaims() {
    const getElementByName = (name: string): HTMLInputElement | null => {
      return document.querySelector(`input[name=${name}]`) as HTMLInputElement
    }

    const getValue = (name: string): string | undefined => {
      const element: HTMLInputElement | null = getElementByName(name)
      return element ? element["value"] : undefined
    }

    const setValue = (name: string, value: string): void => {
      const element: HTMLInputElement | null = getElementByName(name)
      if (element) {
        element["value"] = value
      }
    }

    if (localStorage["userMappingInfo"]) {
      try {
        const parsed = JSON.parse(localStorage["userMappingInfo"])
        const userInfo = parsed?.userInfo
        let element: any

        if (parsed.pool.includes("smartschool")) {
          if (userInfo) {
            const username = userInfo.username || userInfo.userInfo.actualUserName
            if (username) {
              if (!getValue('username')) {
                setValue('username', username)
              }
            }
            if (userInfo?.email) {
              if (!getValue('email')) {
                setValue('email', userInfo.email)
              }
            }
            if (userInfo.nickname) {
              if (!getValue('nickname')) {
                setValue('nickname', userInfo.nickname)
              }
            }
            if (userInfo.surname) {
              if (!getValue('family_name')) {
                setValue('family_name', userInfo.surname)
              }
            }
            if (userInfo.name) {
              if (!getValue('given_name')) {
                setValue('given_name', userInfo.name)
              }
            }
            if (userInfo.extranames) {
              // there is no input for this?
            }
          }
        } else if (parsed.pool.includes("leer")) {
          if (userInfo.given_name) {
            if (!getValue('given_name')) {
              setValue('given_name', userInfo.given_name)
            }
          }
          if (userInfo.family_name) {
            if (!getValue('family_name')) {
              setValue('family_name', userInfo.family_name)
            }
          }
        }

      } catch (e) {
        logError("couldn't parse JSON localStorage.userMappingInfo")
      } 
    }
  }

  ngAfterViewChecked() {
    const label = document.querySelector('[ng-reflect-route="signIn"] [ng-reflect-name="username"] > label')
    let element = document.querySelector('amplify-slot[name="sign-in-footer"] button')
    if (!element) { // if its not signIn then it must be register
      this.prefillRegistrationFormFromClaims()
      return
    }
    if (pwButton != element) {
      pwButton = element
    }
    element = document.querySelector('button[type="submit"]')
    if (submitButton != element) {
      submitButton = element
    }
    element = document.querySelector('input[name="username"]')
    if (usernameInputField != element) {
      usernameInputField = element
    }
    element = document.querySelector('input[name="password"]')
    if (passwordInputField != element) {
      passwordInputField = element
    }

    if (label) label.innerHTML = 'Gebruikersnaam of email'
    if (pwButton && this.currentUserCannotResetPw) {
      pwButton.innerHTML = 'Hulp nodig?'
    } else if (pwButton) {
      pwButton.innerHTML = 'Wachtwoord vergeten?'
    }

    if (oldPasswordInputField != passwordInputField) {
     // oldUsernameInputField?.removeEventListener('keyup', onSubmitByEnter)
     // passwordInputField?.addEventListener('keyup', onSubmitByEnter)
      oldUsernameInputField = passwordInputField
    }

    if (oldUsernameInputField !== usernameInputField) {
      oldUsernameInputField?.removeEventListener('input', onInput)
      usernameInputField?.addEventListener('input', onInput)
      oldUsernameInputField = usernameInputField
    }

    if (oldSubmitButton !== submitButton || !submitButton.onClick) {
      oldSubmitButton?.removeEventListener('click', onSubmitClick)
      submitButton?.addEventListener('click', onSubmitClick)
      oldSubmitButton = submitButton
    }

    if (oldPwButton !== pwButton || !pwButton.onClick) {
      oldPwButton?.removeEventListener('click', onForgotPswButtonClick)
      pwButton?.addEventListener('click', onForgotPswButtonClick)
      oldPwButton = pwButton
    }
  }

  ngOnDestroy(): void {
    document.body.removeEventListener("keypress", onSubmitByEnter)
    this.captchaSubscription?.unsubscribe()
    this.authSubscription?.unsubscribe()
    this.userSubscription?.unsubscribe()
  }

  async getCurrentAmplifyUserAndPool() {
    try {
      const user = await getCurrentAmplifyUser()
      if (!user) return undefined
      const pool = findPoolByClientId(user.pool.clientId)
      const authenticated: boolean = !isMAPPING_USER()
      this.userIsAuthenticated.emit(authenticated)
      if (!authenticated) await Auth.signOut()
      if (pool) {
        this.destination_pool = pool.NAME
      }
      // User is already signed in
      log('User is already signed in:', user)
      // Handle the successful sign-in event here

      return user
    } catch (err) {
      // No user is currently signed in
      logWarning('No user is currently signed in:', err)

      return undefined
    }
  }

  signOut() {
    this.router.navigateByUrl("/logout")
  }


  profile() {
    this.router.navigateByUrl("/profile")
  }

  organizations() {
    this.router.navigateByUrl("/organizations")
  }

  roles() {
    this.router.navigateByUrl("/roles")
  }

}
